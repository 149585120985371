import * as React from 'react';
import { connect } from 'react-redux';
import { Component } from 'react';


export default class Phase1Results extends Component {
    state = {
        __html: ""
    }

    componentWillMount() {
        // fetch the HTML fragment with a local API request
        fetch(`/DNSChallengeResults/Phase1`)
            .then(resp => {
                // fetch returns a readable stream, so translate it into stringified HTML
                return resp.text();
            })
            .then(content => {
                // dangerouslySetInnerHTML requires using an object with an `__html` key
                this.setState({
                    __html: content
                });
            })
            .catch(err => {
                // handle the error
            });
    }

    render() {
        return (
            <div dangerouslySetInnerHTML={this.state} />
        );
    }
}